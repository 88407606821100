import React from 'react'

const Projects = () => {
    return (
        <>
            <div className="projects">
                <img src="/sponsered.1220e388.png" alt="ourProjects" className='img-fluid' />
            </div>
        </>
    )
}

export default Projects