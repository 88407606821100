import React from 'react'

const PartnerImg = ({ img }) => {
    return (
        <>
            <img src={img} alt="img" />
        </>
    )
}

export default PartnerImg